import { FC, PropsWithChildren, useState } from 'react';
import { useServerInsertedHTML } from 'next/navigation.js';
import { createEmotionInstance } from '@gs-ux-uitoolkit-common/style';
import { EmotionInstanceProvider } from '@gs-ux-uitoolkit-react/style';

export type AppRouterStyleRegistryProps = PropsWithChildren<{
    /**
     * A nonce ("number used once") to allow-list the generated `<style>` tag
     * for use with a CSP policy.
     *
     * Because styles are dynamically inserted, a checksum (SRI) cannot be used
     * to secure the `<style>` tag's content, and hence a `nonce` must be used
     * instead.
     *
     * See: https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/nonce
     * for details on implementation.
     *
     * Also see: https://ui.web.gs.com/docs/react/styling-components
     */
    nonce?: string;
}>;

/**
 * For use with server-side rendering in Next.js's "App Router", this component
 * collects all CSS-in-JS styles and inserts them into the `<head>` of the
 * document as it streams to the browser.
 */
export const AppRouterStyleRegistry: FC<AppRouterStyleRegistryProps> = props => {
    const { nonce, children } = props;

    // This code is derived from: https://github.com/mui/material-ui/blob/v5.15.15/packages/mui-material-nextjs/src/v13-appRouter/appRouterV13.tsx
    // which itself is derived from: https://github.com/emotion-js/emotion/issues/2928#issuecomment-1319747902
    const [registry] = useState(() => {
        const emotion = createEmotionInstance();
        const cache = emotion.cache;
        cache.compat = true;

        const emotionDefaultInsert = cache.insert;
        let inserted: { name: string; isGlobal: boolean }[] = [];

        // Override the insert method to support streaming SSR with flush().
        cache.insert = (...args) => {
            const [selector, serialized] = args;

            if (cache.inserted[serialized.name] === undefined) {
                inserted.push({
                    name: serialized.name,
                    isGlobal: !selector,
                });
            }
            return emotionDefaultInsert(...args);
        };

        const flush = () => {
            const prevInserted = inserted;
            inserted = [];
            return prevInserted;
        };

        return { emotion, cache, flush };
    });

    useServerInsertedHTML(() => {
        const inserted = registry.flush();
        if (inserted.length === 0) {
            return null;
        }

        const { cache } = registry;
        let styles = '';
        let dataEmotionAttribute = cache.key;

        const globals: {
            name: string;
            style: string;
        }[] = [];

        inserted.forEach(({ name, isGlobal }) => {
            const style = cache.inserted[name];

            if (typeof style !== 'boolean') {
                if (isGlobal) {
                    globals.push({ name, style });
                } else {
                    styles += style;
                    dataEmotionAttribute += ` ${name}`;
                }
            }
        });

        return (
            <>
                {globals.map(({ name, style }) => (
                    // TODO: Not sure why we might need a separate <style> tag for
                    // each global style, while we can have a single <style> tag
                    // for all non-global styles. But Material UI does this and
                    // there's no documentation about it on the Emotion docs.
                    // Leaving this here in case it's needed, but ideally we'd
                    // have a single <style> tag for global styles.
                    <style
                        nonce={nonce}
                        key={name}
                        data-emotion={`${cache.key}-global ${name}`}
                        dangerouslySetInnerHTML={{ __html: style }}
                    />
                ))}
                {styles && (
                    <style
                        nonce={nonce}
                        data-emotion={dataEmotionAttribute}
                        dangerouslySetInnerHTML={{ __html: styles }}
                    />
                )}
            </>
        );
    });

    return <EmotionInstanceProvider emotion={registry.emotion}>{children}</EmotionInstanceProvider>;
};
